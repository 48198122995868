<template>
  <sim-card-event-list
    :card-id="this.id"
    table-type="sim_card"
  />
</template>

<script>
import SimCardEventList from '@/views/sim_card_events'

export default {
  name: 'ShowSimCardEvents',
  components: { SimCardEventList },
  props: {
    id: {
      type: Number,
      required: true
    }
  }
}
</script>

