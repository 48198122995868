import request from '@/utils/request'

// 查询卡号历史事件
export function findSimCardEvents(params) {
  return request({
    url: `/sim_card_events`,
    method: 'get',
    params: params
  })
}

// 查询卡号历史事件 事件类型选项数据
export function findSimCardEventTypeOptions() {
  return request({
    url: `/sim_card_events/event_type/options`,
    method: 'get'
  })
}

// 查询卡号历史事件 操作人选项数据
export function findSimCardEventOperatorOptions(simCardId) {
  return request({
    url: `/sim_card_events/operator/options`,
    method: 'get',
    params: { sim_card_id: simCardId }
  })
}

