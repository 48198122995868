<template>
  <div>
    <search-sim-card-events
      v-if="isShowSearch"
      @submit="submitSearch"
      :sim-card-id="cardId"
    />

    <a-spin :spinning="loading">
      <a-timeline v-if="data.length > 0">
        <a-timeline-item v-for="event in data" :key="event.id">
          <p>
            <a-space>
              <span>{{ event.created_at }}</span>
              <span>{{ event.event_type }}</span>
              <span>({{ event.operator_full_name }})</span>
            </a-space>
          </p>
          <p>{{ event.content }}</p>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
      <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
      />
    </a-spin>
  </div>
</template>

<script>
import { findSimCardEvents } from '@/api/sim_card_event'
import SearchSimCardEvents from '@/views/sim_card_events/Search'
import Pagination from '@/components/Pagination/index'

export default {
  name: 'SimCardEventList',
  components: {
    SearchSimCardEvents,
    Pagination
  },
  props: {
    tableType: {
      type: String,
      required: true,
      validator: function(value) {
        return ['sim_card_inventory', 'sim_card'].indexOf(value) !== -1
      }
    },
    cardId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: [],
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    // 仅 sim_card 详情有搜索
    isShowSearch() {
      return this.tableType === 'sim_card'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      const params = Object.assign({ card_id: this.cardId, table_type: this.tableType }, this.query)
      findSimCardEvents(params).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination.total_count = res.pagination.total_count
        }
        this.loading = false
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    }
  }
}
</script>
