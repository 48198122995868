<template>
  <a-form
    class="simiot-nested-search-form events-search-form"
    :form="form"
    @submit="handleSearch"
  >
    <a-row :gutter="24">
      <a-col :span="9" :xxl="6">
        <a-form-item label="事件类型">
          <a-select
            v-decorator="['event_type']"
            allow-clear
            show-search
            :loading="loadingEventTypeOptions"
            :filter-option="filterOptions"
            @focus="fetchEventTypeOptions"
          >
            <a-select-option
              v-for="option in eventTypeOptions"
              :key="option.slug"
              :value="option.slug"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="8" :xxl="6">
        <a-form-item label="操作人">
          <a-select
            v-decorator="['operator_id']"
            allow-clear
            show-search
            :loading="loadingOperatorOptions"
            :filter-option="filterOptions"
            @focus="fetchOperatorOptions"
          >
            <a-select-option
              v-for="option in operatorOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <a-space>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
            <a-button @click="handleReset">
              刷新
            </a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { findSimCardEventOperatorOptions, findSimCardEventTypeOptions } from '@/api/sim_card_event'

export default {
  name: 'SearchSimCardEvents',
  props: {
    simCardId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      eventTypeOptions: [],
      operatorOptions: [],
      loadingEventTypeOptions: false,
      loadingOperatorOptions: false,
      form: this.$form.createForm(this, { name: 'sim_card_events_search' })
    }
  },
  methods: {
    fetchEventTypeOptions() {
      if (this.eventTypeOptions.length === 0) {
        this.loadingEventTypeOptions = true
        findSimCardEventTypeOptions().then(res => {
          if (res.code === 0) {
            this.eventTypeOptions = res.data
            this.loadingEventTypeOptions = false
          }
        })
      }
    },

    fetchOperatorOptions() {
      if (this.operatorOptions.length === 0) {
        this.loadingOperatorOptions = true
        findSimCardEventOperatorOptions(this.simCardId).then(res => {
          if (res.code === 0) {
            this.operatorOptions = res.data
            this.loadingOperatorOptions = false
          }
        })
      }
    },

    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    }
  }
}
</script>

<style lang="less" scoped>
.events-search-form {
  margin-bottom: 10px;
}
</style>
